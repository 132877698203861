/*!
Theme Name: Slikkr Absolute
Theme URI: https://www.slikkr.com/
Description: Custom WordPress theme for Absolute Blues Guitar
Author: Sarah Cox
Author URI: https://www.slikkr.com
Version: 0.8.93
Tags:
License:
License URI:
*/

@import "functions";
@import "palette";
@import "variables";
@import "icons";
@import "animations";
@import "mixin";
@import "extend";
@import "foundation";
@import "layout";
@import "carousels";
@import "elements";
@import "alerts";
@import "forms";