$absolute-white          : rgb(255, 255, 254); // #fffffe;
$white                   : $absolute-white;
$absolute-muddy          : rgb(31, 31, 31);
$absolute-black          : $absolute-muddy; // rgb(0, 2, 3); // #000203;
$black                   : $absolute-muddy;

$absolute-red            : #6D0D00;
$absolute-green          : #024005;

$absolute-blue-01        : rgb(28, 38, 49);
$absolute-blue-02        : rgb(34, 46, 59);
$absolute-blue-03        : rgb(40, 55, 70);
$absolute-blue-04        : rgb(47, 65, 82);
$absolute-blue-05        : rgb(53, 72, 93);
$absolute-blue-06        : rgb(80, 109, 139);
$absolute-blue-07        : rgb(116, 145, 175);
$absolute-blue-08        : rgb(162, 181, 202);
$absolute-blue-09        : rgb(209, 218, 228);
$absolute-blue-10        : rgb(233, 237, 242, .45);

$absolute-plum-01        : rgb(49, 28, 39);
$absolute-plum-02        : rgb(58, 34, 46);
$absolute-plum-03        : rgb(70, 40, 55);
$absolute-plum-04        : rgb(81, 47, 64);
$absolute-plum-05        : rgb(94, 54, 75);
$absolute-plum-06        : rgb(139, 80, 111);
$absolute-plum-07        : rgb(175, 116, 146);
$absolute-plum-08        : rgb(201, 161, 182);
$absolute-plum-09        : rgb(229, 210, 220);
$absolute-plum-10        : rgb(242, 232, 237);

$absolute-burgundy-01    : rgb(70, 7, 39);
$absolute-burgundy-02    : rgb(84, 8, 47);
$absolute-burgundy-03    : rgb(100, 10, 57);
$absolute-burgundy-04    : rgb(116, 11, 65);
$absolute-burgundy-05    : rgb(135, 13, 76);
$absolute-burgundy-06    : rgb(200, 19, 113);
$absolute-burgundy-07    : rgb(236, 55, 148);
$absolute-burgundy-08    : rgb(242, 120, 183);
$absolute-burgundy-09    : rgb(249, 190, 220);
$absolute-burgundy-10    : rgb(252, 222, 238);

$absolute-gold-01        : rgb(72, 46, 4);
$absolute-gold-02        : rgb(87, 54, 5);
$absolute-gold-03        : rgb(106, 68, 6);
$absolute-gold-06        : rgb(207, 132, 12);
$absolute-gold-07        : rgb(243, 168, 47);
$absolute-gold-10        : rgb(253, 241, 221);

$absolute-grey-01        : rgb(146, 146, 146);
$absolute-grey-02        : rgb(182, 182, 182);
$absolute-grey-03        : rgb(219, 219, 219);
$absolute-grey-04        : rgb(238, 238, 238);

$page-background         : $absolute-white;
$pale-shade              : rgba($absolute-blue-09, .45); //#dbdbdb45; //$absolute-grey-03;

$background-primary-01   : $absolute-blue-02;
$background-primary-02   : $absolute-blue-05;

$background-secondary-01 : $absolute-burgundy-01;
$background-secondary-02 : $absolute-burgundy-04;

$absolute-blue-text      : $absolute-blue-03;

$gold-primary            : $absolute-gold-06;

$message                 : $absolute-gold-06;
$error                   : $absolute-red;

//+++++++++++++++++++++++
//+++ HELPERS: COLORS +++
//+++++++++++++++++++++++

// Basic colors
$red: #F44336;
$pink: #E91E63;
$purple: #9C27B0;
$deep-purple: #673AB7;
$indigo: #3F51B5;
$blue: #424B5A;
$light-blue: #03A9F4;
$cyan: #00BCD4;
$teal: #009688;
$green: #4CAF50;
$light-green: #8BC34A;
$lime: #CDDC39;
$yellow: #FFEB3B;
$amber: #FFC107;
$orange: #FF9800;
$deep-orange: #FF5722;
$brown: #795548;
$cream: #F4F3E7;
$white: #FFFFFF;
$black: #000000;

// Standard greyscale: number = brightness (HSB)
$grey95: #F2F2F2;
$grey90: #E6E6E6;
$grey80: #CCCCCC;
$grey70: #B3B3B3;
$grey60: #999999;
$grey50: #808080;
$grey40: #666666;
$grey30: #4D4D4D;
$grey20: #333333;
$grey15: #262626;

// Social colors
$dribbble: #EA4C89;
$facebook: #3B5999;
$flickr: #FF0084;
$googleplus: #DD4B39;
$instagram: #E4405F;
$linkedin: #0077B5;
$pinterest: #BD081C;
$snapchat: #FFFC00;
$soundcloud: #ff3300;
$tumblr: #34465D;
$twitter: #55ACEE;
$vimeo: #1AB7EA;
$whatsapp: #25D366;
$youtube: #CD201F;

// Social colors
.dribbble { color: $dribbble; };
.facebook { color: $facebook; };
.flickr { color: $flickr; };
.googleplus { color: $googleplus; };
.instagram { color: $instagram; };
.linkedin { color: $linkedin; };
.pinterest { color: $pinterest; };
.snapchat { color: $snapchat; };
.soundcloud { color: $soundcloud; };
.tumblr { color: $tumblr; };
.twitter { color: $twitter; };
.vimeo { color: $vimeo; };
.whatsapp { color: $whatsapp; };
.youtube { color: $youtube; };

/* Ribbons */
.corner-ribbon.white {
	background : $absolute-white;
	color      : $absolute-black;
}

.corner-ribbon.black {
	background : $absolute-black;
	color      : $absolute-white;
}

.corner-ribbon.blue {
	background : $absolute-blue-07;
}

.corner-ribbon.gold {
	background : $gold-primary;
}

/* Notices */
$info-dark               : $absolute-blue-02;
$info-mid                : #AAACCD;
$info-light              : #D2D3E1;

$danger-dark             : $absolute-red;
$danger-mid              : #CD9292;
$danger-light            : #EBCECE;

$warning-dark            : $absolute-gold-06;
$warning-mid             : #CEAE75;
$warning-light           : #FFEAD1;

$success-dark            : $absolute-green;
$success-mid             : #8CAF8C;
$success-light           : #C5D7C5;