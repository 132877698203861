
.carousel-container {
  overflow: hidden;
  margin: 0 0 5em;

  .carousel-title-row {
    position: relative;
    margin-bottom: 3px;
    h3 {
      margin: 0;
    }

    .carousel-nav {
      position: absolute;
      bottom: 0;
      right: 2px;
      text-align: center;
      -webkit-tap-highlight-color: transparent;

      [class*='owl-'] {
        color: #FFF;
        background: $absolute-blue-02;
        width: 35px;
        height: 35px;
        margin: 0 0 0 3px;
        display: inline-block;
        cursor: pointer;

        &:hover {
          background: $absolute-gold-06;
          text-decoration: none;

          i:before {
            opacity: 1;
          }
        }

        i:before {
          font-size: 2em;
          position: relative;
          top: 0;
          margin-right: 0;
          opacity: 0.8;
        }

      }

    }
  }

  .carousel-item-row {

    .owl-carousel {

      .owl-stage-outer{
        position:relative;
        overflow:hidden;
        -webkit-transform:translate3d(0,0,0);
        transition: height 500ms ease-in-out 0s;

        .owl-stage {
          @extend %flexbox;
          position:relative;
          -ms-touch-action:pan-Y;

          &:after {
            content:".";
            display:block;
            clear:both;
            visibility:hidden;
            line-height:0;
            height:0
          }

          .owl-item{
            background: transparent;
            min-height:1px;
            float:left;
            -webkit-touch-callout:none;

            &:first-of-type {
              .item {
                padding-left: 1px;
              }
            }

            .item {
              height: 100%;
              @extend %flexbox;
              @extend %flexdircol;
              @extend %flexjustifyend;
              padding-top: 1px;
              padding-left: 2px;
              overflow: hidden;
              position: relative;


              h2, a, div {
              }

              .img-outer {

                .image-container {
                  padding-bottom: 56.25%;
                  width: 100%;
                  height: 0;
                  display: block;
                  overflow: hidden;
                  background-size: cover;
                }

              }

              .title {

                background: $absolute-white;
                @extend %flexgrow1;
                @extend %flexbox;
                @extend %flexdircol;
                @extend %flexjustifycenter;

                h2 {
                  font-size: 1.4em;
                  padding: 0.3em 1.4em;
                  margin: 0;
                  a {
                    color: #000;
                  }
                }

              }

              p.go-to-lesson {
                display: block;
                font-size: 1.4em;
                padding: calc(1em/1.4) calc(1em/1.4 *2);
                background-color: $absolute-blue-02;
                color: $absolute-white;

                a {

                  &:hover {
                    color: $absolute-gold-06;

                    i:before {
                      opacity: 1;
                    }
                  }

                  i:before {
                    position: relative;
                    top: 2px;
                    opacity: 0.8;
                  }
                }
              }

              .corner-ribbon {
                line-height: 3.5em;
              }
            }
          }

        }
      }
    }
  }
}

/* core carousel */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }
.owl-carousel .owl-item img {
  display: block;
  width: 100%; }
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }
.owl-carousel.owl-loaded {
  display: block; }
.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }
.owl-carousel.owl-hidden {
  opacity: 0; }
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }
.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }
.owl-carousel.owl-rtl {
  direction: rtl; }
.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0; }
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}