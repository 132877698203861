.form-errors, .form-success {

	&:after {
		@include clearfix-after;
	}
}

.form-success {

	p {
		color: $success-dark;
	}
}

.form-errors {

	p {
		color: $error;
	}
}

#form-container {

	form {

		.field {

			clear: both;
			position: relative;
			padding: .5em 0;

			@include clearfix-after;

			&:first-of-type {
				padding-top: 0;
			}

			.form-label {

				label {

					font-size: 2em;
					display: block;
					margin-bottom: 0.2em;

					span {

						float: right;
						padding-left: 2%;
						position: relative;
						font-size: .75em;

					}
				}

			}

			.hint {
				font-size: .75em;
				&.shift {
					margin-left: 2em;
				}
			}

			.form-input {

				input[type="text"], input[type="email"], input[type="tel"], textarea {
					width: 100%;
					font-size: 2em;
					text-align: left;
					line-height: 1.7em;
					color: #000203;
					padding: 0 .7em;
					border: 0.5px solid #bfbfbf;
					@extend %borderradius;

					&.error {
						border-color: $error;
					}

				}

				select {
					background-color: #FFFFFF;
					padding: .5em 1em;
					width: 100%;
					height: 1.5em;
					font-size: 2em;
					line-height: 1.7em;
					color: $black;
					outline: none;
					border: 0.5px solid #bfbfbf;

					&.error {
						border-color: $error;
					}
				}

				input[type=checkbox] {
					border: 1px solid #b4b9be;
					background: #fff;
					color: #555;
					clear: none;
					cursor: pointer;
					display: inline-block;
					line-height: 0;
					height: 20px;
					margin: -4px 0 0 0;
					outline: 0;
					padding: 0 !important;
					text-align: center;
					vertical-align: middle;
					width: 20px;
					min-width: 20px;
					-webkit-appearance: none;
					-webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
					box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
					-webkit-transition: .05s border-color ease-in-out;
					transition: .05s border-color ease-in-out;

					&:checked:before {
						content: "\ed71";
						margin: 1px 0 0 -2px;
						color: $absolute-blue-02;
					}

					&:checked:before {
						float: left;
						display: inline-block;
						vertical-align: middle;
						width: 22px;
						font: 400 16px/1 abg-icons;
						speak: none;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
					}
				}

			}
		}

	}


}