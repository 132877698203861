%font {
	font-family: -apple-system,
	BlinkMacSystemFont,
	"Segoe UI",
	Roboto,
	Oxygen-Sans,
	Ubuntu,
	Cantarell,
	"Helvetica Neue",
	sans-serif;
}

%boxed {
	@extend %borderradiushalfem;
	border: 1px solid $absolute-blue-09;
	background: $page-background;
}

%box-shadow {
	-webkit-box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
	-moz-box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
	box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
}

%flexbox {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

%noflexbox {
	-webkit-flex: none;
	-moz-flex: none;
	-ms-flex: none;
	-o-flex: none;
	-khtml-flex: none;
	flex: none;
}

%flexdirrow {
	-webkit-flex-direction: row;
	-moz-flex-direction: row;
	-ms-flex-direction: row;
	-o-flex-direction: row;
	flex-direction: row;
}

%flexdircol {
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
}

%flexalignitemscenter {
	-webkit-align-items: center;
	-moz-align-items: center;
	-ms-align-items: center;
	-o-align-items: center;
	-khtml-align-items: center;
	align-items: center;
}

%flexalignitemsstart {
	-webkit-align-items: flex-start;
	-moz-align-items: flex-start;
	-ms-align-items: flex-start;
	-o-align-items: flex-start;
	-khtml-align-items: flex-start;
	align-items: flex-start;
}

%flexjustifycenter {
	-webkit-justify-content: center;
	-moz-justify-content: center;
	-ms-justify-content: center;
	-o-justify-content: center;
	-khtml-justify-content: center;
	justify-content: center;
}

%flexjustifystart {
	-webkit-justify-content: flex-start;
	-moz-justify-content: flex-start;
	-ms-justify-content: flex-start;
	-o-justify-content: flex-start;
	-khtml-justify-content: flex-start;
	justify-content: flex-start;
}

%flexjustifyend {
	-webkit-justify-content: flex-end;
	-moz-justify-content: flex-end;
	-ms-justify-content: flex-end;
	-o-justify-content: flex-end;
	-khtml-justify-content: flex-end;
	justify-content: flex-end;
}

%flexjustifyspacebetween {
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	-ms-justify-content: space-between;
	-o-justify-content: space-between;
	-khtml-justify-content: space-between;
	justify-content: space-between;
}

%flexjustifyspace-around {
	-webkit-justify-content: space-around;
	-moz-justify-content: space-around;
	-ms-justify-content: space-around;
	-o-justify-content: space-around;
	-khtml-justify-content: space-around;
	justify-content: space-around;
}

%flexgrow1 {
	-webkit-flex-grow: 1;
	-moz-flex-grow: 1;
	-ms-flex-grow: 1;
	-o-flex-grow: 1;
	-khtml-flex-grow: 1;
	flex-grow: 1;
}

%flexauto {
	-webkit-box-flex: auto;
	-moz-box-flex: auto;
	-webkit-flex: auto;
	-ms-flex: auto;
	flex: auto;
}



%flexone {
	-webkit-flex: 1;
	-moz-flex: 1;
	-ms-flex: 1;
	-o-flex: 1;
	-khtml-flex: 1;
	flex: 1;
}

%borderradius50pc {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
}

%borderradius {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

%borderradiusoneem {
	-webkit-border-radius: 1em;
	-moz-border-radius: 1em;
	border-radius: 1em;
}

%borderradiushalfem {
	-webkit-border-radius: 0.5em;
	-moz-border-radius: 0.5em;
	border-radius: 0.5em;
}

%borderradiustop5px {
	-webkit-border-top-left-radius: 5px;
	-webkit-border-top-right-radius: 5px;
	-moz-border-top-left-radius: 5px;
	-moz-border-top-right-radius: 5px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
}

%borderradiustophalfem {
	-webkit-border-top-left-radius: 0.5em;
	-webkit-border-top-right-radius: 0.5em;
	-moz-border-top-left-radius: 0.5em;
	-moz-border-top-right-radius: 0.5em;
	border-top-left-radius: 0.5em;
	border-top-right-radius: 0.5em;
}

%borderradiustoprighthalfem {
	-webkit-border-top-right-radius: 0.5em;
	-moz-border-top-right-radius: 0.5em;
	border-top-right-radius: 0.5em;
}

%borderradiusbottomhalfem {
	-webkit-border-bottom-left-radius: 0.5em;
	-webkit-border-bottom-right-radius: 0.5em;
	-moz-border-bottom-left-radius: 0.5em;
	-moz-border-bottom-right-radius: 0.5em;
	border-bottom-left-radius: 0.5em;
	border-bottom-right-radius: 0.5em;
}

%makecircle {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	overflow: hidden;
}

%prevent-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

%release {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	margin-bottom: 0;
}

%boldlink {
	color: $absolute-blue-02;
}

%content-list-item {
	font-size: 1.6em;
	line-height: 1.6em;
	letter-spacing: normal;
	margin-bottom: .3em;
}

%sidebar-list-item {
	font-size: 1.4em;
	line-height: 1.4em;
	letter-spacing: normal;
	margin-bottom: .3em;
}

%nm {
	margin: 0;
}

%ntbm {
	margin-top: 0;
	margin-bottom: 0;
}

%nlrm {
	margin-left: 0;
	margin-right: 0;
}

%ntm {
	margin-top: 0;
}

%nbm {
	margin-bottom: 0;
}

%np {
	padding: 0;
}

%ntbp {
	padding-top: 0;
	padding-bottom: 0;
}

%nlrp {
	padding-left: 0;
	padding-right: 0;
}

%ntp {
	padding-top: 0;
}

%nbp {
	padding-bottom: 0;
}

%tp {
	padding-top: 2em;
}

%bp {
	padding-bottom: 2em;
}

.nbm {
	@extend %nbm;
}