

// ALERTS

.alert-dismissable, .alert-modal {
  padding-right: 35px;
}

.close {
  font-size: 1.6em;
  position: relative;
  top: -.2em;
  right: -0.5em;
  color: inherit;
  @extend %prevent-select;
  float: right;
  font-weight: 700;
  line-height: 1;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}

a.close {
  text-decoration: none !important;
  line-height: 1.2;
}

sup {
  vertical-align: top;
  font-size: inherit;
}

.alert {
  font-size: 1.6em;
  line-height: 1.5em;
  padding: 1em 2em 1em 4em;
  margin-bottom: 20px;
  border: 1px solid transparent;
  position: relative;
  @extend %borderradiushalfem;

  p {
    font-size: 1em;
    vertical-align: text-top;

    &:last-child {
      padding-bottom: 0;
    }

    a {
      font-weight: 700;
    }
  }

  .icon {
    text-align: center;
    width: 45px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 15px;
    @include border-radius-left(.5em);

    i {
      font-size: 20px;
      left: .3em;
      margin-top: -.7em;
      position: absolute;
      top: 50%;

      &:before {
        margin-right: 0;
        margin-left: 0.3em;
      }
    }
  }
}

.alert-success {
  color: $success-dark;
  background-color: $success-light;
  border-color: $success-mid;
  .icon {
    color: $success-light;
    border: 1px solid $success-light;
    background-color: $success-dark;

  }
  a {
    color: $success-dark;
  }
}

.alert-info {
  color: $info-dark;
  background-color: $info-light;
  border-color: $info-mid;
  .icon {
    color: $info-light;
    border: 1px solid $info-light;
    background-color: $info-dark;

  }
  a {
    color: $info-dark;
  }
}

.alert-warning {
  color: $warning-dark;
  background-color: $warning-light;
  border-color: $warning-mid;
  .icon {
    color: $warning-light;
    border: 1px solid $warning-light;
    background-color: $warning-dark;

  }
  a {
    color: $warning-dark;
  }
}

.alert-danger {
  color: $danger-dark;
  background-color: $danger-light;
  border-color: $danger-mid;
  .icon {
    color: $danger-light;
    border: 1px solid $danger-light;
    background-color: $danger-dark;

  }
  a {
    color: $danger-dark;
  }
}

.fade.in {
  opacity: 1;
}

.fade {
  opacity: 0;
  @include transition(opacity, .3s, linear);
}