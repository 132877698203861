.no-js #loader { display: none;  }
.js #loader { display: block; position: absolute; left: 100px; top: 0; }
.sm-loader {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: $absolute-white;
	background-color: $absolute-white;
}

button.cky-show-desc-btn:not(:hover):not(:active) {
	color : #506D8B ! important;
}

#layout-switches {
	display: inline-block;
	float: right;
	margin-bottom: 0;
	position: relative;
	vertical-align: bottom;

	li {
		float: left;
		margin-right: 0.5em;

		a.active {
			color: $absolute-gold-06;
		}

		i {
			font-size: 0.9em;
			&:before {
				top: 2px;
			}
		}
	}

	@media (min-width: 600px) {
		float: none;
		margin-left: 0.7em;
	}
}

h1, h3, p.title {
	margin: 0;
	padding: 1.5em 0 0;
	font-weight: 700;
	font-size: 1.6em;

	@media (min-width: 600px) {
		font-size: 2.4em;
	}
}

h1.page-title {
	margin: 0 0 1em;
	padding-bottom: 1rem;
	//display: inline-block;
	text-transform: uppercase;
	border-bottom: 1px solid $absolute-black;
}

h2 {
	margin: 0;
	padding: 0.5em 0 1.5em;
	font-weight: 700;
	font-size: 2em;

	&.archive-taxonomy-title {
		padding-bottom: 0.5em;
	}

	.membership-guide & {
		margin-top: 2em;
	}

	#content-sidebar & {
		padding: 1em 0;
		text-transform: uppercase;
	}
}

h3 {
	#content-sidebar & {
		padding-top: 0;
		margin-bottom: .3em;
		font-size: 1.8em;
		font-weight: bold;
		text-transform: uppercase;
	}

	.preheader + & {
		padding: 0.5rem 0;
	}
}

h4 {
	margin: 0;

	#content-sidebar & {
		padding: .2em 0;
		margin-top: 1em;
		margin-left: 0.5em;
		font-size: 1.6em;
		font-weight: bold;
	}

	section.tab & {
		padding: .2em 0;
		font-size: 1.4em;
		font-weight: normal;
	}

}

h5 {
	margin: 0;
	padding: 1.5em 0 0;
	font-weight: 700;
	font-size: 1.2em;

	@media (min-width: 600px) {
		font-size: 2em;
	}
}

p {
	font-size: 1rem;
	line-height: 1.3em;
	margin: 0;
	padding: 0 0 1.3em;

	&.centered {
		text-align: center;
	}

	&.preheader {
		padding-top: calc((1em/2)*3.6);
		padding-bottom: 0;
		margin: 0;

	}

	&.button {
		font-size: 1.6em;
		margin: 0.8em 0 0;
		padding-bottom: 0;

		a {

			cursor: pointer;
			@extend %borderradiushalfem;
			@extend %prevent-select;
		}

		#content-sidebar & {
			margin-top: 0.3em;
			text-align: center;
		}
	}

	&.caveat {
		width: 90%;
		margin: 0 auto;
		font-size: 1.2em;
		padding: .5em 0 0;

		#content-sidebar & {
			text-align: center;
			border-top: 1px solid $absolute-black;
			padding-top: 1em;
			margin-top: 3em;
		}

		@media (min-width: 600px) {
			width: 100%;
		}
	}

	section.tab & {
		font-size: 1rem;
		line-height: 1.3em;
	}

	footer & {
		font-size: 1.2em;
		margin-bottom: 1em;
	}
}

blockquote p {
	font-style: italic;
	padding-bottom: 0.7em;
}

strong {
	font-weight: 600;
}

.cta-panel {

	p {
		padding-bottom: 0.7em;
	}

	ul {
		margin-bottom: 1em;
		margin-top: -1em;
		li {
			font-size: 1.6em;
		}
	}
}

.action-button {
	margin: 0;
	color: $absolute-white;
	background-color: $background-secondary-02;
	border: 1px solid unquote(make-transparent($background-secondary-02, .8));
	padding: 0.8em 1.5em 0.7em;
	font-size: 1em;
	font-weight: bold;
	line-height: 1.6em;
	letter-spacing: 2px;
	@extend %borderradius;

	.contact-us & {
		float: right;
	}

	@media (min-width: 600px) {

		&:hover {
			cursor: pointer;
			background: $gold-primary;
			border: 1px solid unquote(make-transparent($gold-primary, .8));
			@include transition(all, .8s, ease);

		}
	}
}

.cta-button-big {
	display: inline-block;
	padding: 20px 42px;
	color: #fff;
	font-size: 1.5em;
	font-weight: 700;
	text-align: center;
	text-shadow: 1px 1px 0 rgba(0,0,0,.25);
	border-width: 1px;
	border-style: solid;
	border-color: $absolute-burgundy-02 $absolute-burgundy-05 $absolute-burgundy-03;
	@extend %borderradius;
	background: $absolute-burgundy-01;
	background-image: -webkit-gradient(linear,left top,left bottom,from($absolute-burgundy-04),to($absolute-burgundy-01));
	background-image: -webkit-linear-gradient(top,$absolute-burgundy-04,$absolute-burgundy-01);
	background-image: -moz-linear-gradient(top,$absolute-burgundy-04,$absolute-burgundy-01);
	background-image: -ms-linear-gradient(top,$absolute-burgundy-04,$absolute-burgundy-01);
	background-image: -o-linear-gradient(top,$absolute-burgundy-04,$absolute-burgundy-01);
	background-image: linear-gradient(to bottom,$absolute-burgundy-04,$absolute-burgundy-01);
	-webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.25);
	-moz-box-shadow: inset 0 1px 0 rgba(255,255,255,.25),0 1px 2px rgba(0,0,0,.25);
	box-shadow: inset 0 1px 0 rgba(255,255,255,.25), 0 1px 2px rgba(0,0,0,.25);

	@media (min-width: 600px) {

		&:hover {
			cursor: pointer;
			background: $gold-primary;
			border: 1px solid unquote(make-transparent($gold-primary, .8));
			@include transition(all, .8s, ease);

		}
	}
}

ol, ul {
	li {
		font-size: inherit;

		a {
			font-size: inherit;
		}
	}
}

a.anchor {
	display: block;
	position: relative;
	top: -73px;
	visibility: hidden;
}

a.standout {
	color: $absolute-burgundy-05;
}

span.no-link {
	opacity: 0.7;
	cursor: not-allowed;
}

#menu-label {
	color: $absolute-white;

	.scrolled &, .inner & {
		color: $absolute-white;
	}

	&:hover {
		cursor: pointer;
	}
}

.site-navigation {

	nav#upper-menu-container {



		ul#upper-menu {

			@media (min-width: 600px) {
				li {

					> span, > a {

						color: $absolute-white;
						-webkit-touch-callout: none;
						-webkit-user-select: none;
						-khtml-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;
					}

					> span {
						padding-right: 0;
					}

					&.member-register {
						background-color: $absolute-gold-06;

						a {
							color: $absolute-black;
							text-transform: uppercase;
							font-weight: bold;

							&:before {
								background-color: $absolute-gold-06;
								-webkit-border-radius: 0.5em;
								-moz-border-radius: 0.5em;
								border-radius: 0.5em;
							}

							&:hover {
								color: $absolute-white;

							}

							@media (min-width: 600px) {
								&:hover:after {
									width: 100%;
									background-color: $absolute-white;
								}
							}

						}
					}

					.sub-menu {
						background-color: $absolute-white;

						li {

							a {
								color: $absolute-black;
								padding: .5em;
								text-align: center;

								&:after {
									display: block;
									content: '';
									position: relative;
									left: 0;
									top: 2px;
									margin: 0 auto;
									height: 2px;
									width: 0;
									-webkit-transition: width 0.25s ease;
									transition: width 0.25s ease;
									background-color: $absolute-gold-06;
								}

								@media (min-width: 600px) {
									&:hover:after {
										width: 100%;
									}
								}
							}
						}
					}

					@media (min-width: 600px) {
						&:hover {
							.sub-menu {
								display: block;
								height: auto;
								max-height: 900px;
								z-index: 6000;
							}
						}

					}
				}

			}



			@media (max-width: 600px){
				display: none;
			}
		}

		ul#mobile-menu {

				-webkit-transition: max-height 1s;
				-moz-transition: max-height 1s;
				-ms-transition: max-height 1s;
				-o-transition: max-height 1s;
				transition: max-height 1s;

				li {
					font-weight: bold;
					color: rgba(255, 255, 255, 0.6);

					&.member-register {
						color: $absolute-gold-06;
					}

					a {
						border-bottom: 1px dotted rgba(255, 255, 255, 0.2);
					}
				}
		}
	}

	nav#main-menu-container {

		ul#main-menu {

			li {
				font-size: 1.6em;
				color: $absolute-black;
				font-weight: bold;

				&.member-register {
					color: $absolute-burgundy-04;
				}
			}
		}
	}
}

#archive-navigation {

	span {
		display: inline-block;
		font-size: 2em;

		a {
			display: block;
			padding: .7em;
		}
	}

	span.pages {
		padding: .7em;

	}

	span.current {
		padding: .7em;

	}

}

.slikkr-tabs {

	input.tab {
		display: none;

		+ label.tab {
			border: 1px solid #ddd;
			border-top: 2px solid #555;
			border-bottom: 1px solid #ddd;
		}

		&:checked + label.tab {
			color: #555;
			border: 1px solid #ddd;
			border-top: 2px solid $absolute-gold-06;
			border-bottom: 1px solid $page-background;
			background: $page-background;
			background-color: $page-background;
		}
	}

	label.tab {
		padding: 6px 8px;
		font-size: 1.3em;
		display: inline-block;
		margin: 0 0 -1px;
		text-align: center;
		color: #bbb;
		border: 1px solid transparent;
		background: $absolute-blue-10;
		background-color: $absolute-blue-10;

		@extend %borderradiustop5px;
		@extend %prevent-select;

		i {
			margin: 0;
			font-size: 18px;

			&:before {
				margin-right: 0;
				left: 2px;
			}
		}

		@media (min-width: 600px) {
			padding: 10px;
			font-size: 1.4em;

			i {
				margin-right: 0.5em;
			}
		}

		&.login-toggle {
			padding: 0;
			border: 1px solid #ddd;
			border-top: 2px solid #555;
			border-bottom: 1px solid #ddd;

			a {
				display: block;
				padding: 10px 15px;
			}
		}

		&:hover {
			color: #888;
			cursor: pointer;
		}
	}

	section.tab {
		display: none;
		padding: 2em;
		border-top: 1px solid #ddd;
		background: $page-background;
		background-color: $page-background;

		@extend %borderradiustoprighthalfem;
		@extend %borderradiusbottomhalfem;

		a {
			color: $absolute-burgundy-05;
			font-weight: bold;
		}

		#current-card-container {
			position: relative;

			&.no-data {
				padding: 5em;
			}
		}

	}

	#tab1:checked ~ #content1,
	#tab2:checked ~ #content2,
	#tab3:checked ~ #content3,
	#tab4:checked ~ #content4 {
		display: block;
	}

	input.tab + br, label.tab + br, label.tab + p {
		display: none;
	}
}

#slikkr-modal {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	opacity: 0;
	z-index: 9999;
	overflow: auto;
	position: fixed;
	visibility: hidden;
	margin-top: -250px;
	background: rgba(0,0,0,.8);

	transition: all .3s ease-in-out;
	-moz-transition: all .3s ease-in-out;
	-webkit-transition: all .3s ease-in-out;

	&.open {
		opacity: 1;
		margin-top: 0px;
		visibility: visible;
	}

	&:target {
		opacity: 1;
		margin-top: 0px;
		visibility: visible;
	}

	.slikkr-modal-content {
		width: 70%;
		padding: 5px;
		background: #FFF;
		max-width: 600px;
		margin: 70px auto;
		position: relative;
		border-radius: 8px;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

		.alert {
			margin-bottom: 0;

			+p:last-child {
				display: none;
			}
		}

		p {
			margin-bottom: 0.7em;
			padding-bottom: 0;
			font-size: 1em;
		}

		p.slikkr-modal-close {
			font-size: 0.7em;
			margin: 2em 0 1em;
			text-align: right;

			a.slikkr-modal-close {
				display: block;
				font-weight: normal;
			}

			+ p:last-child {
				display: none;
			}
		}
	}
}

#social-tools {

	border-top: 1px solid $absolute-black;
	margin-top: 2em;
	padding-top: 2em;

	a {
		color: $absolute-blue-01;
		background-color: $absolute-blue-08;

		&:hover {
			background-color: $absolute-gold-06;
		}
	}
}
#video-container {

	background-color: $absolute-muddy;
	@extend %borderradiushalfem;

	#embed-container {
		background: $absolute-black;
	}
}

article {

	h2 {
		padding: 0;
		margin: 0 0 .5em;
	}

	aside {
		font-size: 2em;

		.featured-image {
			padding-top: 0.2em;

			img {
				display: block;
			}

			+ ul {
				margin-top: 1em;
				margin-bottom: 0.5em;
			}
		}

		.icon-links {

			margin-bottom: 1em;

			i {
				color: $absolute-burgundy-04;


				&:hover {
					color: $absolute-gold-07;
				}
			}
		}
	}

	@media (min-width: 600px) {

		aside {
			font-size: 1.6em;
			width: 30%;
			margin-right: 2em;
		}

		main {
			padding-right: 2em;
		}
	}
}

#lesson-content-container {
	@extend %boxed;
	padding: 2em;
	margin-top: 2em;
}

#bookmarktool {
	position: absolute;
	top: -5px;
	right: 0;
	font-size: 3em;
	color: $absolute-plum-05;

	.disabled {
		opacity: 0.3;
	}

	span {
		@extend %prevent-select;
		cursor: pointer;
	}
}

$imgPath : "img";

.full-membership {

	div {
		overflow: hidden;

		&:nth-of-type(2) {
			margin-top: 2em;

			@media (min-width: 600px) {
				margin-top: 0;
			}
		}
	}

	h3 {
		padding: 1em;

		@media (min-width: 600px) {
			padding: 1em 0;
		}
	}

	p.button {
		margin-top: 1em;
	}

	ul {

		li {
			font-weight: bold;
			padding: 0.5em 0;

			i {
				color: $absolute-gold-07;

				&:before {
					top: 0;
					margin: 0 0.5em;
				}
			}
		}
	}
}

.highlight-band {

	.wrapper {

		div {
			span {
				font-size: 2em;
				color: $absolute-white;

				&:nth-of-type(2) {
					margin: 1em 0;

					@media (min-width: 600px) {
						margin: 0;
					}
				}

				@media (min-resolution: 192dpi) and (max-width: 600px) {
					font-size: 2.6em;
				}
			}
		}
	}

	.caveat {
		color: unquote(make-transparent($absolute-white, 0.8));
		margin-top: 0.5em;
	}
}

#partner-profiles {

	.partner-container {

		padding: 0;
		margin-bottom: 3em;
		width: 100%;
		background: $absolute-white;
		background-color: $absolute-white;
		@extend %box-shadow;

		.partner-header{
			padding: 14em 0 0;
			background: $absolute-muddy url(#{$imgPath}/abg-star.svg) repeat left top;
			background-size: 20px 20px;

			line-height: 0;
			@extend %borderradiustophalfem
		}

		.partner-image {
			border: 5px solid $absolute-white;

			img {
				display: block;
			}
		}

		.partner-details {

			.partner-name {

				p {
					margin-top: 1em;
					margin-bottom: 0.5em;
					padding: 0;
					font-weight: bold;

					span {

						display: block;

						&.name {
							margin: 0;
						}

						&.title {
							margin: 0;
						}
					}
				}

			}

			.partner-social {
				i {
					color: $absolute-burgundy-04;
					&:before {
						font-size: 2.5em;
						margin: 0 0.25em;
					}

					&:hover {
						color: $absolute-gold-07;
					}
				}
			}

			.partner-bio {
				padding: 2em 2em 0;
			}
		}
	}
}

#back-to-top {
	text-align: right;
	color: #666;
	margin-top: 4em;
	margin-bottom: 1em;
	display: block;
	text-decoration: none;
	font-size: 1.6em;

	.icomn {
		margin-left: 7px;
		&:before {
			font-size: 1em;
			position: relative;
			top: 2px;
		}
	}

	@media (min-width: 600px) {
		margin-top: 0;
		margin-bottom: 0;
	}
}

#footer-menu-container {
	background: $absolute-white;
	padding: 4em 3em 2em;

	ul#footer-menu {

		li {
			font-size: 1.6em;
			line-height: 2em;
			float: none;

			a {
				color: $absolute-black;

				&:hover {
					color: $absolute-blue-02;
				}
			}
		}
	}

	@media (min-width: 600px) {
		background: unset;

		ul#footer-menu {

			@include clearfix-after;

			li {
				float: left;
				margin-right: 2em;

				a {
					color: $absolute-white;

					&:hover {
						color: unset;
					}

				}
			}
		}
	}
}

table.lesson-index {

	width: 100%;
	font-size: 1.4em;

	@media (min-width: 700px) {
		width: 80%;
	}

	tr {

		td {
			vertical-align: top;

			&.icon-placeholder {
				width: 1.5em;
			}

			&.item-title {
				line-height:1.5em;

				a {
					white-space: normal;
					display: block;
					padding: 0;
				}
			}

			.marker {
				white-space: nowrap;
			}
		}
	}

}

ul.styled {

	margin: 0;
	padding: 1em 0.5em;

	&:not(#track-list) {
		@extend %borderradiushalfem;
	}

	&#bookmark-list {

		li {

			a {
				font-size: inherit;
				display: block;
			}

			i {
				&:before {
					top: 0;
				}
			}
		}

		.member-account & {

			li {
				@extend %flexbox;
				@extend %flexdirrow;

				span.lesson-link {
					@include flex(1, 1, auto);
					overflow: hidden;

					a {
						width: 100%;
						overflow: hidden;
					}
				}

				span.prune-bookmark {
					@include flex(0, 0, 7%);

					i {
						font-size: 1.5em;
						&:before {
							top: 4px;
							padding-left: 0.5em;
						}
					}

					&:hover {
						cursor: pointer;
					}
				}
			}
		}

	}

	li {
		@extend %content-list-item;

		&:last-of-type {
			margin-bottom: 0 !important;
		}

		&.empty {
			color: rgba($absolute-black, 0.3);
		}

		&.track, &.empty, a {
			padding: 0.3em 0.5em;
			margin-bottom: 0 !important;
		}

		&.track:hover, a:hover {
			background: $absolute-blue-09;
			cursor: pointer;
		}

		span:not(.marker):not(.lesson-link) {
			font-size: calc(1em/1.6*1.2);
		}

		a {
			font-size: inherit;
			display: block;
		}

	}

	#content-sidebar & {

		&#track-list {
			border: 1px solid $absolute-white;
		}

		// border: 1px solid $absolute-white;
		background-color: $absolute-blue-10;

		li {
			@extend %sidebar-list-item;
		}

	}
}

ul#social-menu {

	float: right;

	li {
		float: left;
		margin-left: 1em;

		a {
			width: 1em;
			height: 1em;
			font-size: 3em;
			display: block;

			img {
				width: 100%;
				height: auto;
			}
		}
	}

}

ul#legal-menu {
	float: left;
	li {
		float: left;
		margin-right: 2em;

	}
}



.privacy-policy, .terms-of-use {

	#main {

		li li, li p, li li {
			font-size: inherit;
		}

		h2 {
			font-size: 2.4em;
		}

		h3 {
			font-size: 2.2em;
			padding: 0;
			Margin-bottom: 1em;
		}

		h4 {
			font-size: 2em;
		}

		h5 {
			font-size: 1.8em;
		}

		ul, ol {
			list-style-type: circle;
			padding-left: 4em;
			margin-bottom: 1em;
		}

	}
}



form {

	p {
		label {
			font-size: calc(2em/2);
		}

		input {
			font-size: calc(2em/2);
		}
	}
}


nav#posts-nav {
	margin: 4em auto;
	width: 100%;
	@extend %flexbox;
	@include flexdir(row);

	@media (min-width: 600px) {
		width: 80%;
	}

	div {
		text-align: center;
		@include flex(1, 0, auto);


		a, span.no-link, span.pages, span.current {
			font-size: 1.6em;
		}
	}
}

.shout {
	font-weight: bold;
	display: block;
	margin-bottom: 0.5em;
	text-transform: uppercase;
}

.pullquote {
	margin: 1em 0 2em 0;
	padding: 2em 3em;
	@extend %borderradiushalfem;

	&.right {
		width: 33.3%;
		margin: 1em 0 2em 4em;
		float: right;
	}

	&.left {
		width: 33.3%;
		margin: 1em 4em 2em 0;
		float: left;
	}

	p {
		font-size: 2.2em;
		text-align: center;
		margin-bottom: 0;
	}

}

.breakout {
	padding: 3em 0;
	margin-top: 0;
	margin-bottom: 0;

	&.video {
		margin-bottom: 2em;
		background: $absolute-muddy;
		background-color: $absolute-muddy;

		.wrapper {
			padding: 0;
		}
	}

	&.full-membership {
		margin-bottom: 2.6em;

		.wrapper {

			width: 100%;

			p {
				width: 90%;
				margin: 0 auto;
			}

			@media (min-width: 600px) {

				width: 90%;

				p {
					width: 100%;
				}
			}
		}
	}

	&.highlight-band {

		margin-top: 3em;
		background: $absolute-blue-06;
		border-top: 1px solid $absolute-blue-03;
		border-bottom: 1px solid $absolute-blue-03;
	}

	&.partners {

		padding-top: 1em;
		background: unquote(make-transparent($absolute-blue-05, 0.2));
		background-color: unquote(make-transparent($absolute-blue-05, 0.2));

		.wrapper {
			padding: 0;
		}
	}

	p {

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.wrapper {
		overflow: auto;
		max-width: 900px;
		padding: 0;
	}
}

.assistive-text, .screen-reader-text {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
}

// MARKERS

.marker {
	display: inline-block;
	color: $white;
	font-size: calc(1em/1.4);
	line-height: 1.7em;
	padding: 0 0.5em;
	font-weight: bold;
	text-transform: uppercase;
	background-color: $absolute-gold-06;
	position: relative;
	top: -2px;
	margin-left: 1em;
	@extend %prevent-select;
	@extend %borderradiushalfem;
}

// RIBBONS

.corner-ribbon{
	width: 200px;
	background: #e43;
	position: absolute;
	top: 25px;
	left: -50px;
	text-align: center;
	line-height: 50px;
	letter-spacing: 1px;
	color: #f0f0f0;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

/* Custom styles */

.corner-ribbon.sticky{
	position: fixed;
}

.corner-ribbon.shadow {
	box-shadow: 0 0 3px rgba(0,0,0,.3);
}

/* Different positions */

.corner-ribbon.top-left {
	top: 25px;
	left: -50px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right {
	top: 25px;
	right: -50px;
	left: auto;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left {
	top: auto;
	bottom: 25px;
	left: -50px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right {
	top: auto;
	right: -50px;
	bottom: 25px;
	left: auto;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}



@keyframes anim-rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loading {

	position: absolute;
	width: 4em;
	z-index: 9000;

	&.lessons {
		position: relative;
		top: 180%;
		left: 0;
		text-align: center;

		@media (min-width: 600px) {
			position: absolute;
			width: 100%;
		}
	}

	&.bookmarks {
		top: 40%;
		left: 40%;
	}

	&.card {
		top: 15%;
		left: 5%;
	}

	i {

		display: inline-block;
		font-size: 2em;
		margin: .5em;
		animation: anim-rotate 1s infinite linear;
		color: $absolute-gold-06;

		&:before {
			margin: 0;
			top: 0;
		}
	}
}
.loading--steps {
	animation: anim-rotate 0.5s infinite steps(8);
}
.loading--steps2 {
	animation: anim-rotate 0.5s infinite steps(12);
}