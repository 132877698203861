$icomoon-font-path: "fonts" !default;

$abgi-gbp: \f156;
$abgi-dollar: \f157;
$abgi-youtube: \f167;
$abgi-home: \e901;
$abgi-music: \e94e;
$abgi-coin-dollar: \ea0b;
$abgi-coin-pound: \ea0d;
$abgi-card-payment: \ea16;
$abgi-envelope: \ea32;
$abgi-pin: \ea36;
$abgi-user: \eb0b;
$abgi-group: \eb0c;
$abgi-card-profile: \eb11;
$abgi-spinner: \eb23;
$abgi-search: \eb33;
$abgi-cog: \eb61;
$abgi-multitool: \eb6a;
$abgi-lightbulb: \ebfa;
$abgi-bin: \ebfd;
$abgi-warning: \ec49;
$abgi-list: \ec59;
$abgi-grid: \ec5d;
$abgi-menu: \ec71;
$abgi-download: \ec87;
$abgi-www-globe: \ec91;
$abgi-www-link: \ec99;
$abgi-bookmark: \ecb7;
$abgi-bookmark-o: \ecb8;
$abgi-bookmarks: \ecb9;
$abgi-star-o: \ece3;
$abgi-star: \ece5;
$abgi-heart: \ecef;
$abgi-heart-o: \ecf0;
$abgi-thumbs-up: \ecf5;
$abgi-stack-star: \ed3e;
$abgi-stack-music: \ed4b;
$abgi-stack-play: \ed4c;
$abgi-circle-notification: \ed52;
$abgi-plus: \ed5d;
$abgi-minus: \ed5e;
$abgi-circle-info: \ed64;
$abgi-circle-cross: \ed66;
$abgi-cross: \ed6d;
$abgi-tick: \ed71;
$abgi-sign-out: \ed76;
$abgi-infinite: \edad;
$abgi-circle-up: \ede7;
$abgi-circle-right: \ede8;
$abgi-circle-left: \edea;
$abgi-circle-right-arrow: \edee;
$abgi-circle-left-arrow: \edf2;
$abgi-circle-tick: \ee76;
$abgi-open: \eec8;
$abgi-amazon: \eee6;
$abgi-facebook: \eeef;
$abgi-twitter: \eef5;
$abgi-appleinc: \ef1d;
$abgi-file-pdf: \ef3e;