$imgPath : "img";

@mixin at2x($path, $ext: "png", $w: auto, $h: auto) {
	$at1x_path: "#{$imgPath}/#{$path}.#{$ext}";
	$at2x_path: "#{$imgPath}/#{$path}@2x.#{$ext}";
	$at3x_path: "#{$imgPath}/#{$path}@3x.#{$ext}";

	background-image: url("#{$at1x_path}");

	@media all and (-webkit-min-device-pixel-ratio: 1.5),
	all and (-o-min-device-pixel-ratio: 3/2),
	all and (min--moz-device-pixel-ratio: 1.5),
	all and (min-device-pixel-ratio: 1.5) {
		background-image: url("#{$at2x_path}");
		background-size: $w $h;
	}
}

@mixin plainlist {
	list-style-type: none;
	padding-left: 0;
}

@mixin boxed {
	@include borderradiushalfem;
	border: 1px solid $absolute-blue-09;
	background: $page-background;
}

@mixin borderradiushalfem {
	-webkit-border-radius: 0.5em;
	-moz-border-radius: 0.5em;
	border-radius: 0.5em;
}

@mixin transition($attribute, $duration, $transition) {
	-webkit-transition: $attribute $duration $transition;
	-moz-transition: $attribute $duration $transition;
	-o-transition: $attribute $duration $transition;
	transition: $attribute $duration $transition;
}

@mixin makecircle() {
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	overflow: hidden;
}

@mixin borderradiustop($radius) {
	-webkit-border-top-left-radius: $radius;
	-webkit-border-top-right-radius: $radius;
	-moz-border-top-left-radius: $radius;
	-moz-border-top-right-radius: $radius;
	border-top-left-radius: $radius;
	border-top-right-radius: $radius;
}

@mixin borderradius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin border-radius-left($curve) {
	-webkit-border-top-left-radius: $curve;
	-webkit-border-bottom-left-radius: $curve;
	-moz-border-top-left-radius: $curve;
	-moz-border-bottom-left-radius: $curve;
	border-top-left-radius: $curve;
	border-bottom-left-radius: $curve;
	overflow: hidden;
}

@mixin border-radius-right($curve) {
	-webkit-border-top-right-radius: $curve;
	-webkit-border-bottom-right-radius: $curve;
	-moz-border-top-right-radius: $curve;
	-moz-border-bottom-right-radius: $curve;
	border-top-right-radius: $curve;
	border-bottom-right-radius: $curve;
	overflow: hidden;
}

@mixin box-shadow() {
	-webkit-box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
	-moz-box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
	box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
}

@mixin flexbox() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin flex($grow, $shrink, $basis) {
	-webkit-box-flex: $grow $shrink $basis;
	-moz-box-flex:  $grow $shrink $basis;
	-webkit-flex:  $grow $shrink $basis;
	-ms-flex:  $grow $shrink $basis;
	flex:  $grow $shrink $basis;
}

@mixin flexdir($dir) {
	-webkit-flex-direction: $dir;
	-moz-flex-direction: $dir;
	-ms-flex-direction: $dir;
	-o-flex-direction: $dir;
	flex-direction: $dir;
}



@mixin flexflow($type) {
	-webkit-flex-flow: $type;
	-moz-flex-flow: $type;
	-ms-flex-flow: $type;
	-o-flex-flow: $type;
	flex-flow: $type;
}

@mixin flexalignitems($where) {
	-webkit-align-items: $where;
	-moz-align-items: $where;
	-ms-align-items: $where;
	-o-align-items: $where;
	-khtml-align-items: $where;
	align-items: $where;
}

@mixin flexaligncontent($where) {
	-webkit-align-content: $where;
	-moz-align-content: $where;
	-ms-align-content: $where;
	-o-align-content: $where;
	-khtml-align-content: $where;
	align-content: $where;
}

@mixin flexalignself($where) {
	-webkit-align-self: $where;
	-moz-align-self: $where;
	-ms-align-self: $where;
	-o-align-self: $where;
	-khtml-align-self: $where;
	align-self: $where;
}

@mixin clearfix-before {

	&:before {
		content: "\0020";
		display: block;
		height: 0;
		overflow: hidden;
	}
}

@mixin clearfix-after {

	&:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
}