$imgPath : "img";

body {
	letter-spacing: .03em;
	background: $absolute-blue-10;
	background-color: $absolute-blue-10;
	color: $absolute-black;
	margin: 0;
	@extend %font;

	&.home {
		background: $absolute-white;
		background-color: $absolute-white;
	}
}

header {
	color: $absolute-white;

	.row {

		&.upper {
			background-color: $absolute-muddy;
		}

		&.middle {

			background-color: $absolute-blue-05;
		}

		&.lower {
			background-color: $absolute-white;
			text-align: center;
			padding: 1em 0;
		}
	}
}

.home {
	.parallax, .content-container {
		text-align:center;
		position:relative;

		.inner {
			position: relative;
			width: 100%;
			padding: 0;
		}
	}

	.content-container {
		width: 90%;
		max-width: 900px;
		margin: 0 auto;

		.inner {

			.content {
				padding-top: 0;

				.boxed {
					margin-top: 0;
					margin-bottom: 3em;
				}
			}
		}
	}

	.parallax {
		width: 100%;
		background-color: $absolute-black;
		background-repeat: no-repeat;
		background-position: center;

		&.banner1 {
			background-image:url(#{$imgPath}/abg-banner.png);
			background-size:  cover;
			background-position: center;
			display:table;
			height: 200px;

			@media (min-width: 600px) {
				background-image:url(#{$imgPath}/abg-banner@2x.png);
				height: 200px;
			}

			@media (min-width: 768px) {
				background-image:url(#{$imgPath}/abg-banner@3x.png);
				height: 250px;
			}

		}

		@supports not (-webkit-overflow-scrolling: touch) {
			background-attachment: fixed;
		}

		.inner {
			width: 50%;
			height: auto;
			margin: 8% auto;
			background: unquote(make-transparent($absolute-black,0.2));

			@media (min-width: 600px) {
				width: 30%;
				margin: 8% auto;
			}

			@media (min-width: 768px) {
				width: 30%;
				margin: 3% auto;
			}

			.content {
				height: 100%;
				width: 100%;

				.logo-container {
					width: 100%;
					margin: 0 auto;
				}

				h1, p {
					color: $absolute-white;
				}

				h1 {
					padding: 0 2em;
				}

				a {
					color: $absolute-white;
				}
			}
		}
	}
}

.boxed {
	background: $absolute-white;
	background-color: $absolute-white;
	padding: 0 3em;
}

.content-container {

	.content {

		&.with-sidebar {

			#content-sidebar {
				background-color: $absolute-white;
				@extend %borderradiushalfem;

				h3 {

					i:before {
						margin-right: 0.3em;
					}
				}

				li {
					font-size: 1.6em;

					i:before {
						margin-right: 0.5em;
					}
				}

				.page-with-contact-form & {
					background-color: transparent;

				}
			}

			@media (min-width: 800px){

				#content-main {
					width: 68%;
				}

				#content-sidebar {

					h3 {
						i:before {
							font-size: 1.3em;
						}
					}
				}
			}

		}

	}

	.archive &, .blog & {

		article {

			margin-bottom: 3em;

			p:last-of-type {
				margin: 0;
				padding: 0;
			}
		}
	}
}

.sidebar-section {

	background-color: $absolute-blue-10;
	@extend %borderradiushalfem;
	@extend %box-shadow;

	p {
		font-size: 1.6em;
	}

	h4 + ul {
		margin: 0.3em 0 0;
	}

}

footer {

	border-top: 2px solid $absolute-black;
	background-color: $absolute-muddy;

	p {
		margin-top: 2em;
		font-size: 1.2em;
	}

	p, li, a {
		color: $absolute-white;
	}

}

.footnote {
	margin-bottom: 5em;
	text-align: center;

	.single-lesson & {
		margin-top: 5em;
	}
}

.featured-image {
	img {
		width: 100%;
		height: auto;
		display: block;
		border: 1px solid unquote(make-transparent($background-primary-01, 0.3));
	}
}

.nopadding:not(.breakout) {
	p {
		margin: 0;
	}
}

a.moretag {
	font-size: calc(1em/2 * 1.6);
	white-space: nowrap;
}

img.alignleft {
	float: left;
	margin-right: 1em;
	margin-bottom: .5em;
	display: block;
	border: 0.05em solid unquote(make-transparent($background-primary-01, 0.3));
}

html ::selection, body ::selection, button ::selection, input ::selection, select ::selection, textarea ::selection {
	background: unquote(make-transparent($background-primary-01, 0.3));
	color: $absolute-white;
}